<template>
  <div class="">
    <loader v-if="isLoading"></loader>
    <div class="main-view" v-if="showLoading">
      <b-notification ref="element" :type="notificationType" :closable="false">
          {{msg}}
      </b-notification>
    </div>
  </div>
</template>

<script>
// import { setTimeout } from 'timers';
import appConfig from '../app_config.json';

export default {
  name: 'AuthCallback',
  data() {
    return {
      appDescription: appConfig.description,
      showLoading: false,
      isLoading: true,
      notificationType: 'is-info',
      msg: 'We\'re getting your session ready.  Please wait.',
    };
  },
  mounted() {
    const { tenant } = this.$route.query;
    this.$auth
      .handleAuthentication()
      .then(() => {
        if (this.$store.state.roles.length > 0) {
          this.$router.push({ name: 'landing', params: { tenant } });
        } else {
          this.$userInfoDialog(`You do not have access to ${this.appDescription}. Please contact your system administrator`);
        }
      })
      .catch((err) => {
        if (err.error === 'invalid_token') {
          this.$auth.login(tenant);
        } else {
          this.showLoading = true;
          if (err.errorDescription === 'user is blocked') {
            this.setMsg('is-danger', 'Your user is blocked. Please contact your system administrator');
          } else {
            this.setMsg('is-danger', 'We were unable to setup your session.  Please try to log in again.');
          }
        }
      })
      .finally(() => { this.isLoading = false; });
  },
  methods: {
    setMsg(type, message) {
      this.msg = message;
      this.notificationType = type;
    },
  },
};
</script>
