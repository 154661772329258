/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
const KxUserMessages = {
  install(Vue, options) {
    /**
     * @param { boolean } isFormDirty - Has the form been modified
     * @param { string } yesRoute to navigate if users click yes
     * @param { string } noRoute to navigate if users click no
     */
    Vue.prototype.$userInfoDialog = function (message, title) {
      this.$buefy.dialog.alert({
        title: 'Information' || title,
        message,
        type: 'is-info',
        hasIcon: true,
        icon: 'alert-circle-outline',
        iconPack: 'mdi',
      });
    };
    Vue.prototype.$userErrorDialog = function (message) {
      this.$buefy.dialog.alert({
        title: 'Error',
        message: message || 'An error occurred. Please try again later.',
        type: 'is-danger',
        hasIcon: true,
        icon: 'alert-circle-outline',
        iconPack: 'mdi',
      });
    };
    Vue.prototype.$userConfirmDialog = function (isFormDirty, yesRoute, noRoute) {
      if (isFormDirty) {
        this.$buefy.dialog.confirm({
          title: 'Are you sure?',
          message: 'You have unsaved changes, do you want to cancel?',
          confirmText: 'Yes',
          cancelText: 'No',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.$router.push({ name: yesRoute });
          },
        });
      } else {
        this.$router.push({ name: noRoute });
      }
    };
  },
};

export default KxUserMessages;
