function checkUrl(value) {
  const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/; //eslint-disable-line
  return urlRegex.exec(value);
}

function validateFieldLength(field, model, next, charactersCount) {
  return model[field.key] ? next(model[field.key].length <= charactersCount) : next(false);
}

const cardstreamFields = [
  {
    key: 'merchantId',
    type: 'input-with-field',
    required: true,
    templateOptions: {
      properties: {
        data: '',
        placeholder: 'Merchant ID',
        icon: 'tag-outline',
      },
      wrapper: {
        properties: {
          label: 'Merchant ID',
        },
      },
    },
    validators: {
      length: {
        expression: (field, model, next) => validateFieldLength(field, model, next, 20),
        message: 'This Merchant ID must be no longer than 20 characters',
      },
    },
  },
  {
    key: 'signingKey',
    type: 'input-with-field',
    required: true,
    templateOptions: {
      properties: {
        data: '',
        required: true,
        icon: 'key-variant',
        placeholder: 'Please enter signing key',
      },
      wrapper: {
        properties: {
          label: 'Signing Key',
        },
      },
    },
    validators: {
      minlength: {
        expression: (field, model, next) => (model[field.key]
          ? next(model[field.key].length >= 5)
          : next(false)),
        message: 'This field length must be at least 5 characters long',
      },
      maxlength: {
        expression: (field, model, next) => validateFieldLength(field, model, next, 100),
        message: 'This field must be no longer than 100 characters',
      },
    },
  },
  {
    key: 'mmsUrl',
    type: 'input-with-field',
    required: true,
    templateOptions: {
      properties: {
        type: 'url',
        required: true,
        placeholder: 'Gateway URL',
        icon: 'link-variant',
      },
      wrapper: {
        properties: {
          label: 'Gateway URL',
        },
      },
    },
    validators: {
      looksOk: {
        expression: (field, model, next) => {
          next(checkUrl(model[field.key]));
        },
        message: 'The URL is not valid',
      },
    },
  },
  {
    key: 'alternativePaymentMethods',
    type: 'select-with-field',
    templateOptions: {
      options: [
        {
          text: 'Apple Pay',
          value: 'APPLE_PAY',
        },
        {
          text: 'Google Pay',
          value: 'GOOGLE_PAY',
        },
      ],
      properties: {
        multiple: 'true',
      },
      wrapper: {
        properties: {
          addons: false,
          label: 'Alternative Payment Methods',
        },
      },
    },
  },
];

export default cardstreamFields;
