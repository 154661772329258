export default class Interface {
  constructor(...args) {
    this.fields = args;
  }

  implementedBy(cls) {
    for (const arg of this.fields) { //eslint-disable-line
      if (!cls[arg]) {
        throw new Error(
          `Class ${cls.constructor.name} does not implement method "${arg}"`,
        );
      }
    }
  }
}
