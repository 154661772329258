/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
import auth0 from 'auth0-js';
import EventEmitter from 'events';
import decode from 'jwt-decode';
import auth0Config from './auth0-variables';
import router from '../router';

// In addition to base example from https://auth0.com/docs/quickstart/spa/vuejs/01-login#configure-auth0
import store from '../store';
import appConfig from '../app_config.json';

const pciWebAuth = new auth0.WebAuth({
  audience: auth0Config.audience,
  domain: auth0Config.domain,
  clientID: auth0Config.clientId,
  responseType: 'id_token token',
  scope: 'openid profile email',
});

const localStorageKey = 'loggedIn';
const loginEvent = 'loginEvent';

class AuthService extends EventEmitter {
  // Starts the user login flow
  login(tenant) {
    pciWebAuth.authorize({
      access_type: tenant,
      connection: 'Username-Password-Authentication',
      redirectUri: `${window.location.origin}/callback?tenant=${tenant}`,
    });
  }

  // Handles the callback request from Auth0
  handleAuthentication() {
    return new Promise((resolve, reject) => {
      pciWebAuth.parseHash((err, authResult) => {
        if (err) {
          if (err.error === 'invalid_token' || err.error === 'login_required') {
            this.login(router.history.current.params.tenant);
          }
          reject(err);
        } else {
          this.localLogin(authResult);
          resolve(authResult.accessToken);
        }
      });
    });
  }

  async localLogin(authResult) {
    // These 3 line are in addition to the example from https://auth0.com/docs/quickstart/spa/vuejs/01-login#configure-auth0
    await this.checkRoles(authResult.accessToken);
    this.accessToken = authResult.accessToken;
    this.profile = authResult.idTokenPayload;

    // Convert the JWT expiry time from seconds to milliseconds
    this.tokenExpiry = new Date(this.accessToken.exp * 1000);
    localStorage.setItem(localStorageKey, 'true');

    // These 3 line are in addition to the example from https://auth0.com/docs/quickstart/spa/vuejs/01-login#configure-auth0
    localStorage.setItem('accessToken', this.accessToken);
    localStorage.setItem('tokenExpiry', decode(this.accessToken).exp * 1000);
    store.dispatch('setProfile', this.profile);
    store.dispatch('setAccessToken', this.accessToken);

    this.emit(loginEvent, {
      loggedIn: true,
      profile: this.profile,
      state: authResult.appState || {},
    });
  }

  renewTokens(tenant) {
    return new Promise(() => {
      if (!this.isAuthenticated()) {
        this.login(tenant);
      }
    });
  }

  clearUserData() {
    store.dispatch('setProfile', {});
    store.dispatch('setRoles', []);
  }

  logOut() {
    localStorage.removeItem(localStorageKey);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('tokenExpiry');

    this.clearUserData();

    this.accessToken = null;
    this.tokenExpiry = null;
    this.profile = null;

    pciWebAuth.logout({
      federated: true,
      cancel: window.location.href,
    });

    this.emit(loginEvent, { loggedIn: false });
  }

  isAuthenticated() {
    // the following to get the token expiry is different from https://auth0.com/docs/quickstart/spa/vuejs/01-login#configure-auth0
    return (
      Date.now() < localStorage.getItem('tokenExpiry')
      && localStorage.getItem(localStorageKey) === 'true'
    );
  }

  // checkRoles is in addition to base example from https://auth0.com/docs/quickstart/spa/vuejs/01-login#configure-auth0

  checkRoles(token) {
    store.dispatch('setRoles', {});
    const decodedToken = decode(token);
    if (decodedToken) {
      store.dispatch('setRoles', this.getPciRoles(decodedToken));
    }
  }

  getPciRoles(decodedToken) {
    const roles = [];

    const tenants = decodedToken['https://payments.mykinetic.io/tenants'];
    for (const [tenantId, tenantData] of Object.entries(tenants)) {
      roles.push(
        tenantData.permissions.map(permission => ({
          permission,
          roleApplication: appConfig.name,
          roleTenant: tenantId,
        })),
      );
    }
    return roles;
  }
}

export default new AuthService();
