function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default {
  key: 'email',
  type: 'input-with-field',
  required: true,
  templateOptions: {
    properties: {
      type: 'email',
      required: true,
    },
    wrapper: {
      properties: {
        label: 'Email',
        addons: false,
      },
    },
  },
  validators: {
    emailFormat: {
      expression: (field, model, next) => next(validateEmail(model[field.key])),
      message: 'This field must be a valid email',
    },
  },
};
