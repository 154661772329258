import emailField from './emailField';

const userCreateSettings = {
  cancelRoute: 'users',
  entityName: 'User',
  method: 'POST',
  createOrUpdate: 'userCreate',
  postDataParams: ['model'],
  afterCreate: 'users',
  submitText: 'Add User',
  baseModel: {
    isLoading: false,
    form: {},
    model: {
      email: null,
      name: null,
    },
  },
  baseFields: [
    emailField,
    {
      key: 'name',
      type: 'input-with-field',
      required: true,
      templateOptions: {
        properties: {
          type: 'name',
          required: true,
        },
        wrapper: {
          properties: {
            label: 'Name',
            addons: false,
          },
        },
      },
    },
  ],
};

export default userCreateSettings;
