import dynamicFields from './dynamicFields';
import baseFields from './baseFields';
import baseModel from './baseModel';

const merchantEditSettings = {
  cancelRoute: 'merchants',
  dynamicFields,
  entityName: 'Merchant',
  method: 'PUT',
  getDataMethod: 'merchantById',
  getDataParams: ['merchantId'],
  putDataMethod: 'merchantEdit',
  putDataParams: ['data', 'id'],
  createOrUpdate: 'merchantEdit', // data, tenant, id
  afterCreate: 'merchants',
  submitText: 'Update',
  baseModel,
  baseFields,
};

export default merchantEditSettings;
