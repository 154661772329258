<template>
  <div v-if="mainTitle" class="mainTitle">
    <h1 class="title">{{mainTitle}} {{adapter}}</h1>
  </div>
</template>
<script>
import { mapState } from 'vuex';
/* istanbul ignore file */
export default {
  name: 'PageTitle',
  props: {
    title: { type: String },
    subHeading: { type: String },
  },
  computed: {
    adapter() { return this.$route.params.adapter || ''; },
    ...mapState({
      loggedIn: state => state.loggedIn,
    }),
    mainTitle() {
      return this.title || this.$route.meta.title;
    },
    subtitle() {
      return `${this.subHeading || this.$route.meta.subHeading} ${this.$route.params.tenant || ''}`;
    },
    tenant() {
      return this.$route.params.tenant;
    },
  },
};
</script>

<style lang="scss" scoped>
  .mainTitle {
    background: white;
    left: 200px;
    padding: 20px 0 20px 20px;
    position: fixed;
    top: 45px;
    width: calc(100vw);
    z-index: 6;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    > .title {
      font-size: 1.5em;
    }

    //media query here plus ellipsis
    @media screen and (max-width: 768px) {
      padding: 20px 0 20px 15px;
      left: 0;
    }
  }
</style>
