/* eslint-disable */

import Interface from './Interface';

const FormBuilderable = new Interface('setFields', 'setSubmit');

export class FormBuilder {
  constructor(h, data) {
    this.data = data;
    this.h = h;
    this.fields = [];
    FormBuilderable.implementedBy(this);
  }

  formButton(child) {
    return this.h(
      'div',
      {
        class: 'field',
      },
      [
        this.h(
          'div',
          {
            class: 'control',
          },
          [child],
        ),
      ]
    );
  }

  formInput(child, f = {}) {
    return this.h(
      'div',
      {
        class: 'field',
      },
      [
        this.h(
          'label',
          {
            class: 'label',
          },
          [
            this.h('span', f.name),
            this.h(
              'div',
              {
                class: 'control',
              },
              [child],
            )
          ]
        )
      ]
    );
  }

  getDataForField(id) {
    return this.data[id];
  }

  setFields(fields) {
    this.fields.push(
      fields.map((f) => {
        if(f.type === 'select') {
          return this.formInput(
            [this.h('select', {
              attrs: {
                id: f.id,
              },
              class: {
                select: true,
                control: true,
              },
              on: {
                input (event) {
                }
              },
              domProps: {
                name: f.id,
                value: this.getDataForField(f.id),
              },
            }, this.getDataForField(f.id).map((item) => this.h('option', {
              attrs: {
                value: item[f.idFieldName],
              },
              domProps: {
                innerHTML: item[f.valueFieldName], //https://vuejs.org/v2/guide/render-function.html
              }
            }, item)))],
            f
          );
        }

        return this.formInput(
          this.h('input', {
            class: {
              input: true,
            },
            attrs: {
              id: f.id,
              autocomplete: 'off',
            },
            domProps: {
              value: this.getDataForField(f.id),
              type: f.type || 'text',
              placeholder: f.name,
              name: f.id,
            },
          }),
          f
        );
      })
    );
  }

  monkey() {
    alert('it worked');
  }

  setSubmit(text, formSubmitMethod) {
    this.fields.push(
      this.formButton(
        this.h('input', {
          class: 'button is-link',
          on: {
            'click': this.monkey,
          },
          domProps: { 
            type: 'button', 
            value: text,
          },
        }),
        { value: text },
      )
    );
  }

  getForm() {
    return this.h('form', {}, this.fields);
  }
}
export class FormDirector {
  constructor(formSubmitMethod) {
    this.formSubmitMethod = formSubmitMethod;
  }

  makeUserSignInForm(builder) {
    builder.setFields([
      {
        name: 'Username',
        id: 'username',
        type: 'select',
        idFieldName: 'id',
        valueFieldName: 'value',
      },
      {
        name: 'Password',
        id: 'password',
        value: 'secret',
        type: 'password',
      },
      {
        name: 'Email',
        id: 'email',
        value: 'tom@tom.com',
        type: 'input',
      }
    ]);
    builder.setSubmit('Sign In', this[this.formSubmitMethod]);
  }
  makeUserRegisterForm(builder) {
    builder.setFields([
      {
        name: 'Username',
      },
      {
        name: 'Email',
      },
      {
        name: 'Password',
        type: 'password',
      },
      {
        name: 'Confirm',
        type: 'password',
      }
    ]);
    builder.setSubmit('Register');
  }

  makePaymentForm(builder) {
    builder.setFields([
      {
        name: 'Credit Card',
        type: 'password',
      }
    ]);
    builder.setSubmit('Submit Payment');
  }
}
