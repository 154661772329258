const baseModel = {
  isLoading: false,
  form: {},
  model: {
    id: null,
    paymentProvider: null,
    name: null,
    merchantId: null,
  },
};

export default baseModel;
