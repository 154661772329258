import Vue from 'vue';
import VeeValidate from 'vee-validate';
import Buefy from 'buefy';
import VueFormly from 'vue-formly';
import VueFormlyBuefy from 'vue-formly-buefy';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';

import AuthPlugin from './plugins/auth';
import Loader from './components/Loader.vue';
import FormBuilder from './components/formBuilder/FormBuilder.vue';
import dataService from './api/DataService';
import KxUserMessages from './plugins/KxUserMessages';

import App from './App.vue';
import router from './router';
import './assets/scss/app.scss';
import store from './store';

// eslint-disable-next-line no-undef
Vue.use(AuthPlugin);
Vue.use(Buefy);
Vue.use(KxUserMessages);
Vue.use(VeeValidate, { inject: false });
Vue.use(VueFormly);
Vue.use(VueFormlyBuefy);
Vue.use(VueMoment, { moment });
Vue.component('loader', Loader);
Vue.component('form-builder', FormBuilder);

Vue.config.productionTip = false;

// axiosSetup();

new Vue({
  router,
  store,
  dataService,
  render: h => h(App),
}).$mount('#app');
