<template>
  <div id="app">
     <header class="header">

        <img class="app-image" src="//cdn.kxcloud.net/images/Kinetic_Logo_White_Trans.png">

        <div class="header-profile-wrapper">
         <b-dropdown aria-role="list">
            <template #trigger="{ active }">
                <b-button
                    :label="tenant"
                    type="is-primary"
                    @click="toggleDropdown"
                    :icon-right="active ? 'menu-up' : 'menu-down'" />
            </template>

            <b-dropdown-item
            aria-role="listitem"
            v-if="!tenants">
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
              </div></b-dropdown-item>
              <template v-if="tenants">
            <b-dropdown-item
                aria-role="listitem"
                v-for="tenant in tenants"
                v-bind:key="tenant.tenantCode"
                @click="switchTenant(tenant.tenantCode)">{{ tenant.tenantCode }}</b-dropdown-item>
              </template>
        </b-dropdown>
          <div v-if="PROFILE && PROFILE.name">
              <span class="header-username">{{PROFILE.name}}</span>
          </div>
          <b-icon
            id="header-logout-button"
            aria-label="Logout"
            class="header-logout is-primary"
            @click.native="logout"
            icon="logout">
            </b-icon>
        </div>
      </header>

      <b-icon
        id="burger-menu"
        aria-label="Burger Menu"
        @click.native="toggleMenu"
        :icon="burgerIcon"
        custom-class="main-burger-icon">
      </b-icon>
      <aside
        aria-label="menu"
        class="menu-wrapper">
          <div :class="['menu', { 'menuHidden': !isMenuOpen }]">
            <ul v-if="hasAccess && tenant" class="menu-list">
              <li>
                <router-link
                  role="link"
                  :class="[{ 'menu-selected': menuItem === 'merchants' }]"
                  @click.native="closeMenu"
                  :to="{ name: 'merchants', params: { tenant } }">
                  <b-icon
                    icon="city">
                  </b-icon>Merchants</router-link>
              </li>
              <li>
                <router-link
                  role="link"
                  :class="[{ 'menu-selected': menuItem === 'paymentplans' }]"
                  @click.native="closeMenu"
                  :to="{ name: 'landing', params: { tenant } }">
                  <b-icon
                    icon="cash-multiple">
                  </b-icon>Payment Plans</router-link>
              </li>
              <li>
                <router-link
                  role="link"
                  :class="[{ 'menu-selected': menuItem === 'keys' }]"
                  @click.native="closeMenu"
                  :to="{ name: 'keytab', params: { tenant } }">
                  <b-icon
                    icon="key">
                  </b-icon>Public Key</router-link>
              </li>
              <li>
                <router-link
                  role="link"
                  :class="[{ 'menu-selected': menuItem === 'integrations' }]"
                  @click.native="closeMenu"
                  :to="{ name: 'integrationstab', params: { tenant } }">
                  <b-icon
                    icon="application">
                  </b-icon>Integrations</router-link>
              </li>
              <li>
                <router-link
                  role="link"
                  :class="[{ 'menu-selected': menuItem === 'users' }]"
                  @click.native="closeMenu"
                  :to="{ name: 'users', params: { tenant } }">
                  <b-icon
                     icon="account">
                  </b-icon>Users</router-link>
              </li>
               <li>
                <router-link
                  role="link"
                  :class="[{ 'menu-selected': menuItem === 'auditRequests' }]"
                  @click.native="closeMenu"
                  :to="{ name: 'auditRequests', params: { tenant } }">
                  <b-icon
                     icon="arrow-collapse-right">
                  </b-icon>Audit Requests</router-link>
              </li>
            </ul>
          </div>
      </aside>
    <page-title id="page-title"/>
    <breadcrumbs id="breadcrumbs"/>
    <router-view/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Breadcrumbs from './components/Breadcrumbs.vue';
import PageTitle from './components/PageTitle.vue';

export default {
  name: 'app',
  data() {
    return {
      profile: {},
      windowWidth: window.innerWidth,
      menuOpen: false,
      tenants: null,
    };
  },
  async created() {
    window.addEventListener('resize', this.handleWindowResize);
  },
  beforeDestroy() /* istanbul ignore next */ {
    window.removeEventListener('resize', this.handleWindowResize);
  },
  methods: {
    async toggleDropdown() {
      this.tenants = null;
      const response = await this.$dataService(this.tenantCode).getUserTenants();
      this.tenants = response.data;
    },
    logout() /* istanbul ignore next */ {
      this.$auth.logOut();
    },
    switchTenant(newTenant) {
      const currentRoute = this.$route;
      this.$router.push({
        name: currentRoute.name,
        params: { tenant: newTenant },
      });
      this.$router.go();
    },
    closeMenu() {
      this.menuOpen = false;
    },
    autoCloseMenu(newWidth) {
      if (newWidth > 768) {
        this.menuOpen = false;
      }
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.autoCloseMenu(this.windowWidth);
    },
    toggleMenu(event, item) {
      if (item && !item.child) this.isMenuOpen = !this.isMenuOpen;
      if (!item) this.isMenuOpen = !this.isMenuOpen;
    },
  },
  computed: {
    menuItem() /* istanbul ignore next */ {
      return this.$route.meta.menu;
    },
    token() {
      return localStorage.getItem('accessToken');
    },
    tenant() {
      return this.$route.params.tenant;
    },
    hasAccess() {
      if (this.$store.state.roles.length > 0) {
        return true;
      }
      return false;
    },
    ...mapState([
      'roles',
      'accessToken',
    ]),
    ...mapGetters([
      'PROFILE',
      'IS_LOGGED_IN',
    ]),
    burgerIcon() {
      return this.menuOpen ? 'close' : 'menu';
    },
    isMenuOpen: {
      get() {
        return this.menuOpen;
      },
      set() {
        this.menuOpen = !this.menuOpen;
      },
    },
  },
  components: {
    Breadcrumbs,
    PageTitle,
  },
};
</script>

<style lang="scss">

.kc-icon-app {
  color: white;
  left: 15px;
  position: fixed;
  top: 10px;
}

#close-icon > i {
  color: white;
}

#app {
  padding-bottom: 1rem;
}

fieldset {
    border: 0;
    padding: .5em 0em;
}

* {
  font-family: 'HelveticaNeue', Arial !important;
}

</style>
