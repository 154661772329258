function checkUrl(value) {
  const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/; //eslint-disable-line
  return urlRegex.exec(value);
}

function validateFieldLength(field, model, next) {
  return model[field.key] ? next(model[field.key].length <= 20) : next(false);
}

const wpmFields = [{
  key: 'merchantId',
  type: 'input-with-field',
  required: true,
  templateOptions: {
    properties: {
      data: '',
      placeholder: 'Merchant ID',
      icon: 'tag-outline',
    },
    wrapper: {
      properties: {
        label: 'Merchant ID',
      },
    },
  },
  validators: {
    length: {
      expression: validateFieldLength,
      message: 'This Merchant ID must be no longer than 20 characters',
    },
  },
},
{
  key: 'mmsUrl',
  type: 'input-with-field',
  required: true,
  templateOptions: {
    properties: {
      type: 'url',
      required: true,
      placeholder: 'Gateway URL',
      icon: 'link-variant',
    },
    wrapper: {
      properties: {
        label: 'Gateway URL',
      },
    },
  },
  validators: {
    looksOk: {
      expression: (field, model, next) => {
        next(checkUrl(model[field.key]));
      },
      message: 'The URL is not valid',
    },
  },
},
{
  key: 'interfaceStatus',
  type: 'select-with-field',
  templateOptions: {
    options: [
      {
        text: 'Test',
        value: 'TEST',
      },
      {
        text: 'Live',
        value: 'LIVE',
      },
    ],
    properties: {
      placeholder: 'Select an interface status',
    },
    wrapper: {
      properties: {
        addons: false,
        label: 'Interface Status',
      },
    },
  },
},
];

export default wpmFields;
