const baseFields = [{
  key: 'id',
  type: 'hidden-field',
}, {
  key: 'paymentProvider',
  type: 'merchant-types',
  required: true,
  templateOptions: {
    properties: {
      data: null,
      placeholder: 'Choose a payment provider type',
      icon: 'bank',
    },
    wrapper: {
      properties: {
        label: 'Payment Provider Type',
      },
    },
  },
},
{
  key: 'name',
  type: 'input-with-field',
  tooltip: 'test',
  required: true,
  templateOptions: {
    properties: {
      data: '',
      icon: 'tag-outline',
    },
    wrapper: {
      properties: {
        label: 'Name',
      },
    },
  },
  validators: {
    length: {
      expression: (field, model, next) => next(model[field.key].length <= 100),
      message: 'This name must be no longer than 100 characters',
    },
  },
},
];

export default baseFields;
