const baseModel = {
  isLoading: false,
  form: {},
  model: {
    userId: null,
    email: null,
    name: null,
    roles: null,
    blocked: null,
  },
};

export default baseModel;
