const countryCodes = {
  key: 'countryCode',
  type: 'country-code',
  required: true,
  templateOptions: {
    properties: {
      data: null,
    },
    wrapper: {
      properties: {
        addons: false,
        label: 'Country Code',
      },
    },
  },
};

export default countryCodes;
