<template>
  <div class="loader-wrapper">
    <div
      class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style lang="scss">
    .spinner {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .spinner > div {
      width: 18px;
      height: 18px;
      background-color: #2E4680;

      border-radius: 25%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }
    .spinner .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
    .spinner .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
    @-webkit-keyframes sk-bouncedelay {
      0%, 80%, 100% { -webkit-transform: scale(0) }
      40% { -webkit-transform: scale(1.0) }
    }
    @keyframes sk-bouncedelay {
      0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
      } 40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
      }
    }

  .loader-wrapper {
    left: 0;
    top: 0;
    background: rgba(0,0,0, 0.3);
    position: fixed;
    align-content: center;
    display: flex;
    height: calc(100vh);
    justify-content: center;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    z-index: 10;
  }
</style>
