<script>
/* eslint-disable */
import { FormDirector, FormBuilder } from './FormBuilders.js';
export default {
  props: ['formMethod','formData'],
  render(h) {
    const director = new FormDirector();
    const builder = new FormBuilder(h, this.$props.formData);
    director[this.formMethod](builder);
    return builder.getForm();
  }
};
</script>
