import baseModel from './baseModel';
import emailField from './emailField';

const userEditSettings = {
  cancelRoute: 'users',
  entityName: 'User',
  method: 'PUT',
  getDataMethod: 'getUserById',
  getDataParams: ['userId'],
  putDataMethod: 'userEdit',
  putDataParams: ['data', 'userId'],
  createOrUpdate: 'editUser', // data, tenant, id
  afterCreate: 'users',
  submitText: 'Update',
  baseModel,
  baseFields: [
    {
      key: 'userId',
      type: 'hidden-field',
    },
    emailField,
    {
      key: 'name',
      type: 'input-with-field',
      required: true,
      templateOptions: {
        properties: {
          type: 'name',
          required: true,
        },
        wrapper: {
          properties: {
            label: 'Name',
            addons: false,
          },
        },
      },
    },
    {
      key: 'roles',
      type: 'roles',
      templateOptions: {
        properties: {
          data: null,
          placeholder: 'Choose a role',
          icon: 'bank',
        },
        wrapper: {
          properties: {
            label: 'Roles',
          },
        },
      },
    },
    {
      key: 'blocked',
      type: 'blocked',
      templateOptions: {
        properties: {
          data: null,
        },
        wrapper: {
          properties: {
            label: 'Blocked',
          },
        },
      },
    },
  ],
};

export default userEditSettings;
