import createMutationsSharer from 'vuex-shared-mutations';
import createPersistedState from 'vuex-persistedstate';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createMutationsSharer({ predicate: ['mutation1', 'mutation2'] }),
    createPersistedState(),
  ],
  state: {
    accessToken: null,
    roles: {},
    profile: {},
    loggedin: false,
  },
  mutations: {
    SET_ACCESS_TOKEN: (state, accessToken) => {
      Vue.set(state, 'accessToken', accessToken);
    },
    SET_ROLES: (state, roles) => {
      Vue.set(state, 'roles', roles);
    },
    SET_PROFILE: (state, profile) => {
      Vue.set(state, 'profile', profile);
    },
    SET_LOGGED_IN: (state) /* istanbul ignore next */ => {
      Vue.set(state, 'loggedin', true);
    },
    SET_LOGGED_OUT: (state) /* istanbul ignore next */ => {
      localStorage.removeItem('auth');
      localStorage.removeItem('vuex'); // persisted vuex storage (for between refreshes)
      Vue.set(state, 'loggedin', false);
    },
  },
  actions: {
    setAccessToken(context, accessToken) {
      context.commit('SET_ACCESS_TOKEN', accessToken);
    },
    setRoles(context, roles) {
      context.commit('SET_ROLES', roles);
    },
    setProfile(context, profile) {
      context.commit('SET_PROFILE', profile);
    },
    login(context) /* istanbul ignore next */ {
      context.commit('SET_LOGGED_IN');
    },
    logout(context) /* istanbul ignore next */ {
      context.commit('SET_LOGGED_OUT');
    },
  },
  getters: {
    PROFILE: state => state.profile,
    IS_LOADING: /* istanbul ignore next */ state => state.isLoading,
    IS_LOGGED_IN: /* istanbul ignore next */ state => state.loggedin,
    ROLES: /* istanbul ignore next */ state => state.roles,
  },
});
