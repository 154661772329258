function validateFieldLength(field, model, next) {
  return model[field.key] ? next(model[field.key].length <= 50) : next(false);
}

const vyneFields = [
  {
    key: 'paymentKey',
    type: 'input-with-field',
    required: true,
    templateOptions: {
      properties: {
        type: 'text',
        required: true,
        placeholder: 'Payment Key',
        icon: 'key',
      },
      wrapper: {
        properties: {
          label: 'Payment Key',
        },
      },
    },
    validators: {
      length: {
        expression: validateFieldLength,
        message: 'This name must be no longer than 50 characters',
      },
    },
  },
  {
    key: 'paymentSecretKey',
    type: 'input-with-field',
    required: true,
    templateOptions: {
      properties: {
        type: 'password',
        required: true,
        placeholder: 'Payment Secret Key',
        icon: 'lock',
      },
      wrapper: {
        properties: {
          label: 'Payment Secret Key',
        },
      },
    },
    validators: {
      length: {
        expression: validateFieldLength,
        message: 'This name must be no longer than 50 characters',
      },
    },
  },
  {
    key: 'merchantId',
    type: 'input-with-field',
    required: true,
    templateOptions: {
      properties: {
        type: 'text',
        required: true,
        placeholder: 'Destination Account ID',
        icon: 'bank-transfer-in',
      },
      wrapper: {
        properties: {
          label: 'Destination Account ID',
        },
      },
    },
    validators: {
      length: {
        expression: validateFieldLength,
        message: 'This name must be no longer than 50 characters',
      },
    },
  },
  {
    key: 'uat',
    type: 'select-with-field',
    templateOptions: {
      options: [
        {
          text: 'UAT',
          value: 'UAT',
        },
        {
          text: 'Live',
          value: 'LIVE',
        },
      ],
      properties: {
        placeholder: 'Select an interface status',
      },
      wrapper: {
        properties: {
          addons: false,
          label: 'Interface Status',
        },
      },
    },
  },
  {
    key: 'countryCode',
    type: 'select-with-field',
    templateOptions: {
      options: [
        {
          text: 'GB',
          value: 'GB',
        },
      ],
      properties: {
        placeholder: 'Select the country code',
      },
      wrapper: {
        properties: {
          addons: false,
          label: 'Country code',
        },
      },
    },
  },
];

export default vyneFields;
