<template>
  <div class="breadcrumbs">
    <ul>
      <li
        v-for="(breadcrumb, idx) in breadcrumbList"
        :key="idx"
        @click="routeTo(idx)"
        :class="{'linked': !!breadcrumb.link}">
        {{ breadcrumb.name }}
      </li>
    </ul>
  </div>
</template>

<script>
/* istanbul ignore file */
export default {
  name: 'Breadcrumb',
  data() {
    return {
      breadcrumbList: [],
    };
  },
  mounted() { this.updateList(); },
  watch: { $route() { this.updateList(); } },
  methods: {
    routeTo(pRouteTo) {
      if (this.breadcrumbList[pRouteTo].link) this.$router.push(this.breadcrumbList[pRouteTo].link);
    },
    updateList() { this.breadcrumbList = this.$route.meta.breadcrumb; },
  },
};
</script>

<style lang="scss" scoped>
  .breadcrumbs {
    background: white;
    left: 200px;
    padding: 0 0 0 20px;
    position: fixed;
    top: 112px;
    width: calc(100vw);
    z-index: 5;

    @media screen and (max-width: 768px) {
      display: block;
      left: 0;
      padding: 0 18px;
    }
  }

  ul {
    display: flex;
    justify-content: left;
    list-style-type: none;
    margin: 0;
    padding: 15px 0 0 0;
  }

  ul > li {
    display: flex;
    float: left;
    width: auto;
    color: black;
    font-size: 1em;
    cursor: default;
    align-items: center;
  }

  ul > li:not(:last-child)::after {
    content: '/';
    float: right;
    font-size: 1em;
    margin: 0 .5em;
    color: black;
    cursor: default;
  }

  .linked {
    cursor: pointer;
    font-size: 1em;
    font-weight: normal;

    &:hover {
      text-decoration: none;
    }
  }
</style>
