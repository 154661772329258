const currencyCodes = {
  key: 'currencyCode',
  type: 'currency-codes',
  required: true,
  templateOptions: {
    properties: {
      data: null,
    },
    wrapper: {
      properties: {
        addons: false,
        label: 'Currency Code',
      },
    },
  },
};

export default currencyCodes;
