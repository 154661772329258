<template>
  <div class="main-view main-view-no-title">
    <span v-html="msg"></span>

    <div v-if="errstring">
    <br>
    Please quote:-
    <h3 class="error-string" v-html="errstring"></h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    msg: {
      type: String,
      default: 'An error occurred. Please contact your system administrator',
    },
    errstring: {
      type: String,
    },
  },
};
</script>

<style scoped>
  .error-string {
    font-size: 8pt;
  }
</style>
