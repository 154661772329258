const merchantCreateSettings = {
  cancelRoute: 'merchants',
  entityName: 'Merchant',
  method: 'POST',
  createOrUpdate: 'merchantCreate',
  postDataParams: ['model', 'id'],
  afterCreate: 'merchants',
  submitText: 'Add Merchant',
  baseModel: {
    isLoading: false,
    form: {},
    model: {
      type: null,
      name: null,
      merchantId: null,
    },
  },
  baseFields: [{
    key: 'paymentProvider',
    type: 'merchant-types',
    required: true,
    templateOptions: {
      properties: {
        data: 'test',
      },
      wrapper: {
        properties: {
          addons: false,
          label: 'Payment Provider Type',
        },
      },
    },
  },
  {
    key: 'name',
    type: 'input-with-field',
    required: true,
    templateOptions: {
      properties: {
        data: '',
        placeholder: 'Name',
      },
      wrapper: {
        properties: {
          addons: false,
          label: 'Name',
        },
      },
    },
    validators: {
      length: {
        expression: (field, model, next) => next(model[field.key].length <= 100),
        message: 'This name must be no longer than 100 characters',
      },
    },
  },
  ],
};

export default merchantCreateSettings;
